var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "curator-detail" },
    [
      _vm.loading
        ? _c("CSpinner", {
            staticStyle: { position: "absolute" },
            attrs: { color: "primary", size: "lg" },
          })
        : _vm._e(),
      !_vm.loading && _vm.userBack
        ? _c(
            "CButton",
            {
              staticClass: "cbtn cbtn-user-back mb-2",
              attrs: { to: _vm.userBack, color: "primary" },
            },
            [
              _c("CIcon", { attrs: { name: "cil-arrow-left" } }),
              _vm._v(" " + _vm._s(_vm.$t("lk.training.labelBackBtn")) + " "),
            ],
            1
          )
        : _vm._e(),
      _vm.showControls
        ? _c(
            "div",
            { staticClass: "curator-detail__controls" },
            [
              _vm.can_edit
                ? _c(
                    "CButtonGroup",
                    { staticClass: "curator-detail__header__btns mr-4" },
                    [
                      _vm.author.active
                        ? _c(
                            "CButton",
                            {
                              staticClass: "curator-detail__header__btn",
                              attrs: {
                                color: _vm.author.active ? "success" : "dark",
                                disabled: true,
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("general.isActive")) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.author.active
                        ? _c(
                            "CButton",
                            {
                              staticClass: "curator-detail__header__btn",
                              attrs: {
                                color: !_vm.author.active ? "danger" : "dark",
                                disabled: true,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("general.isNotActive")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$route.params.back
                ? _c(
                    "CButton",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        size: "sm",
                        color: "warning",
                        to: _vm.$route.params.back,
                      },
                    },
                    [_c("CIcon", { attrs: { name: "cil-arrow-left" } })],
                    1
                  )
                : _vm._e(),
              _vm.can_edit && _vm.author?.id
                ? _c(
                    "CButton",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        size: "sm",
                        color: "success",
                        disabled: _vm.loading,
                        to: {
                          name: _vm.selfUpdate
                            ? "edit-self-author"
                            : "edit-author",
                          params: _vm.selfUpdate
                            ? { back: { name: "self-author" } }
                            : {
                                id: _vm.author.id,
                                back: {
                                  name: "item-author",
                                  params: {
                                    id: _vm.author.id,
                                    back: { name: "authors" },
                                  },
                                },
                              },
                        },
                      },
                    },
                    [_c("CIcon", { attrs: { name: "cil-pencil" } })],
                    1
                  )
                : _vm._e(),
              _vm.can_delete && _vm.author?.id
                ? _c(
                    "CButton",
                    {
                      attrs: {
                        color: "danger",
                        size: "sm",
                        disabled: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showRemoveConfirmModal = true
                        },
                      },
                    },
                    [_c("CIcon", { attrs: { name: "cil-x" } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.can_delete && _vm.author?.id
        ? _c("RemoveConfirmModal", {
            attrs: {
              id: String(_vm.author?.id),
              itemName: _vm.author.id + ". " + _vm.author.fullName,
              confirmationValue: _vm.author.id + ". " + _vm.author.fullName,
              show: _vm.showRemoveConfirmModal,
              loading: _vm.loading,
            },
            on: {
              close: function ($event) {
                _vm.showRemoveConfirmModal = false
              },
              success: _vm.removeItem,
            },
          })
        : _vm._e(),
      _vm.author?.id
        ? _c("div", { staticClass: "curator-detail__wrapper" }, [
            _c("div", { staticClass: "curator-detail__info" }, [
              _c("div", { staticClass: "curator-detail__picture-wrapper" }, [
                _c("div", { staticClass: "curator-detail__picture" }, [
                  _vm.author.avatarPath
                    ? _c("img", {
                        attrs: {
                          src: _vm.author.avatarPath,
                          alt: _vm.author.fullName,
                        },
                      })
                    : _c("div", { staticClass: "curator-detail__plug" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              viewBox: "0 0 35 38",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M31.8423 28.2997L22.5668 23.4655L26.1349 18.8408C26.791 18.0024 27.1804 16.9783 27.1804 15.8795V8.90569C27.1804 4.50534 22.8559 0.938477 17.5208 0.938477C12.1857 0.938477 7.86115 4.50534 7.86115 8.90569V15.8795C7.86115 16.9783 8.2505 18.0024 8.92222 18.8623L8.90662 18.8402L12.4747 23.4649L3.19926 28.2991C1.52443 29.1822 0.430664 30.7077 0.430664 32.4439V37.7531L34.6109 37.7096V32.4439C34.6109 30.7077 33.5164 29.1822 31.8661 28.3108L31.8423 28.2991V28.2997Z",
                              },
                            }),
                          ]
                        ),
                      ]),
                ]),
              ]),
              _c("div", { staticClass: "curator-detail__info-content" }, [
                _vm.author.social_networks
                  ? _c(
                      "div",
                      { staticClass: "curator-detail__networks" },
                      _vm._l(_vm.author.social_networks, function (network) {
                        return network.link
                          ? _c("a", {
                              staticClass: "curator-detail__network-item",
                              attrs: { href: network.link, target: "_blank" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.socialNetworkSvg(network.title)
                                ),
                              },
                            })
                          : _vm._e()
                      }),
                      0
                    )
                  : _vm._e(),
                _c("h4", { staticClass: "title curator-detail__name" }, [
                  _vm._v(_vm._s(_vm.author.fullName)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "curator-detail__content" }, [
              _vm.author.about
                ? _c("div", { staticClass: "curator-detail__desc" }, [
                    _c(
                      "h3",
                      { staticClass: "curator-detail__subtitle title" },
                      [_vm._v(_vm._s(_vm.$t("lk.authors.aboutTitle")))]
                    ),
                    _c("div", {
                      staticClass: "curator-detail__text",
                      domProps: { innerHTML: _vm._s(_vm.author.about) },
                    }),
                  ])
                : _vm._e(),
              _vm.author.links && _vm.author.links.length
                ? _c("div", { staticClass: "curator-detail__links" }, [
                    _c(
                      "h3",
                      { staticClass: "curator-detail__subtitle title" },
                      [_vm._v(_vm._s(_vm.$t("lk.authors.links")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.author.links, function (linkItem) {
                        return linkItem.title && linkItem.link
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-lg-6 mb-2 curator-detail__links-item",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "curator-detail__links-link",
                                    attrs: {
                                      href: linkItem.link,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("CIcon", {
                                      staticClass: "mr-1 mb-1",
                                      attrs: { name: "cil-link", size: "sm" },
                                    }),
                                    _vm._v(" " + _vm._s(linkItem.title) + " "),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm.author.isVideo
                ? _c(
                    "div",
                    { staticClass: "curator-detail__video" },
                    [
                      _c("VideoPlayer", {
                        attrs: {
                          lessonId: "",
                          saveTime: true,
                          video: _vm.author.video,
                          controls: true,
                          types: ["public"],
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.author.courses.length
                ? _c(
                    "div",
                    { staticClass: "curator-detail__courses" },
                    [
                      _c(
                        "h3",
                        { staticClass: "curator-detail__subtitle title" },
                        [_vm._v(_vm._s(_vm.$t("lk.authors.coursesTitle")))]
                      ),
                      _c("list-training", {
                        attrs: {
                          to: "training-detail",
                          courses: _vm.author.courses,
                          isCarousel: true,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }